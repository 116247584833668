<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="scannedData"            
                    placeholder="สแกนบาร์โค้ด ที่นี่"
                    :prepend-inner-icon="icons.mdiBarcodeScan"
                    @keydown.enter="searchProductByBarCode"
                    outlined
                    dense
                    v-if="!disabled"
                ></v-text-field>
            </v-col>
        </v-row>        
        <v-simple-table v-if="$root.getWindowWidth() > 900">
            <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-center" style="width: 50px">
                            #
                        </th>
                        <th class="text-center" style="min-width: 100px">
                            รูป
                        </th>
                        <th class="text-center" style="width: 100%;">
                            สินค้า
                        </th>                        
                        <th class="text-center" style="min-width: 200px">
                            จำนวน
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>                  
                    <tr v-for="(item, index) in $attrs.value.fromOrderItems" :key="item.id + '_' + index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <light-box v-if="!!item.imageURL"
                        :src="item.imageURL"
                        max-width="100"
                        max-height="100"
                        ></light-box>
                        <product-image v-else :image="null" />
                      </td>
                      <td style="border-bottom: none">
                          {{ item.name }}
                          <v-textarea
                            v-if="item.showNote"
                            v-model="item.note"
                            outlined
                            :disabled="disabled"
                          ></v-textarea>
                          <div 
                            class="text--red text-caption" 
                            style="cursor: pointer"
                            v-else
                            @click="item.showNote = true"
                          ><v-icon>{{ icons.mdiPlaylistEdit }}</v-icon> หมายเหตุ</div>                          
                      </td>
                      <td style="border-bottom: none">
                        <v-text-field
                            class="input-align-right my-auto"                            
                            outlined
                            dense
                            v-model.number="item.quantity"
                            :rules="[validators.required, validators.minValueValidator(item.quantity, 1), validators.integerValidator]"
                            :disabled="disabled"
                        ></v-text-field>
                      </td>
                      <td class="pb-6" style="border-bottom: none">
                        <v-btn icon @click="deleteItem(index)" v-if="!disabled">
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                    <tr v-if="$attrs.value.fromOrderItems.length == 0">
                      <td colspan="5" class="text-center py-6 text-h4">โปรดเลือก หรือ สแกนสินค้า</td>
                    </tr>
                    <tr v-if="!disabled">
                      <td colspan="5" style="border-bottom: none" class="px-0">
                        <div class="cols-12 col-md-6 px-0">
                          <delay-autocomplete
                            outlined                            
                            v-model="searchItem"             
                            placeholder="พิมพ์เพื่อค้นหาสินค้า"
                            no-data-text="ไม่พบข้อมูล"
                            :url="url + '/searchProduct?warehouse_id=' + warehouse_id"
                            dense
                            :lazySearch="true"
                            return-object
                            ref="productAutocomplete"
                            item-text="name"
                            item-value="id"
                            hide-selected
                            :filter="filterItem"
                          >
                            <template v-slot:item="{ item, parent }">
                              <div style="width: 100px; height: 100px;" class="d-flex justify-center align-center">
                                <product-image :image="!!item.images && !!item.images[0] ? item.images[0].URI : null" :key="item.id" />                              
                              </div>
                              <div class="ml-4 d-flex flex-column">
                                <div>{{ item.name }}</div>
                                <div v-if="!!item.inventory && !!item.inventory[0]">ย้ายได้ : {{ item.inventory[0].quantity - item.inventory[0].reserved }}</div>
                                <div v-if="item.sku" class="text--disabled">
                                  {{ item.sku }}
                                </div>
                              </div>                              
                            </template>
                          </delay-autocomplete>
                        </div>                     
                      </td>
                    </tr>                    
                </tbody>
            </template>
        </v-simple-table>
        <div class="product-input-mobile" v-else>
          <div class="title text-center">รายการสินค้า</div>
          <div class="product-item" v-for="(item, index) in $attrs.value.fromOrderItems" :key="item.id + '_' + index">                           
            <div class="product-item-tr">
              <div class="product-item-image">
                <light-box v-if="!!item.imageURL"
                :src="item.imageURL"
                max-width="100"
                max-height="100"
                ></light-box>
                <product-image v-else :image="null" />
              </div>
              <div class="product-item-name">
                {{ item.name }}
                <v-textarea
                  v-if="item.showNote"
                  v-model="item.note"
                  outlined
                  :disabled="disabled"
                ></v-textarea>
                <div 
                  class="text--red text-caption" 
                  style="cursor: pointer"
                  v-else
                  @click="item.showNote = true"
                ><v-icon>{{ icons.mdiPlaylistEdit }}</v-icon> หมายเหตุ</div>
              </div>
              <div class="pb-6" style="border-bottom: none">
                <v-btn icon @click="deleteItem(index)" v-if="!disabled">
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </div>
            </div>                        
            <div class="product-item-tr">
              <div class="product-item-header py-2">จำนวน</div>
              <div class="product-item-content">
                <v-text-field
                  class="input-align-right my-auto"                  
                  outlined
                  dense
                  v-model.number="item.quantity"
                  :rules="[validators.required, validators.minValueValidator(item.quantity, 1), validators.integerValidator]"
                  :disabled="disabled"
                ></v-text-field>
              </div>
            </div>
            <div class="product-item-tr">
              <div class="product-item-header">รวม</div>
              <div class="product-item-content text-right">{{ item.priceSum }}</div>
            </div>            
            
            <v-divider class="py-2"></v-divider>
          </div>
          <div class="text-center py-6 text-h4" v-if="$attrs.value.fromOrderItems.length == 0">โปรดเลือก หรือ สแกนสินค้า</div>
          <div v-if="!disabled">
            <delay-autocomplete
              outlined              
              v-model="searchItem"             
              placeholder="พิมพ์เพื่อค้นหาสินค้า"
              no-data-text="ไม่พบข้อมูล"
              :url="url + '/searchProduct?warehouse_id=' + warehouse_id"
              dense
              :lazySearch="true"
              return-object
              ref="productAutocomplete"
              item-text="name"
              item-value="id"
              hide-selected
              :filter="filterItem"
            >
              <template v-slot:item="{ item, parent }">
                <div style="width: 100px; height: 100px;" class="d-flex justify-center align-center">
                  <product-image :image="!!item.images && !!item.images[0] ? item.images[0].URI : null" :key="item.id" />                              
                </div>
                <div class="ml-4 d-flex flex-column">
                  <div>{{ item.name }}</div>
                  <div v-if="!!item.inventory && !!item.inventory[0]">ย้ายได้ : {{ item.inventory[0].quantity - item.inventory[0].reserved }}</div>
                  <div v-if="item.sku" class="text--disabled">
                    {{ item.sku }}
                  </div>
                </div>                            
              </template>
            </delay-autocomplete>
          </div>
        </div>
    </div>    
</template>
<script>
import { IVMOrderItem } from '@/classes/order/IVMOrderItem'
import ToogleTextField from '@/components/ToogleTextField.vue'
import { asyncGet } from '@/helpers/asyncAxios'
import {
  integerValidator,
  lengthValidator,
  maxLengthValidator,
  maxValueValidator,
  minLengthValidator,
  minValueValidator,
  numberValidator,
  required,
} from '@core/utils/validation'
import { mdiBarcodeScan, mdiDeleteOutline, mdiPlaylistEdit } from '@mdi/js'
import LightBox from '../LightBox.vue'
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    warehouse_id: {
      type: Number,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scannedData: null,
      searchItem: null,
      icons: {
        mdiBarcodeScan,
        mdiPlaylistEdit,
        mdiDeleteOutline,
      },
      scannedCache: [],
      validators: {
        required,
        lengthValidator,
        minLengthValidator,
        maxLengthValidator,
        integerValidator,
        numberValidator,
        minValueValidator,
        maxValueValidator,
      },
    }
  },
  watch: {
    searchItem(value) {
      if (!value) return

      const order = this.$attrs.value

      const { id, name, images, sku, barCode, unit } = value

      order.addOrderItem(
        new IVMOrderItem({
          id: id,
          images: images,
          name: name,
          sku: sku,
          barCode: barCode,
          note: null,
          quantity: 1,
          unit: unit,
        }),
      )

      this.$emit('input', order)

      let that = this
      this.$nextTick(() => {
        that.searchItem = null
      })
    },
  },
  methods: {
    addItem({ id, name, images, sku, barCode, unit }) {
      const order = this.$attrs.value
      const currentItems = order.fromOrderItems

      const foudItem = currentItems.find(data => data.id == id)

      if (!foudItem) {
        order.addOrderItem(
          new IVMOrderItem({
            id: id,
            images: images,
            name: name,
            sku: sku,
            barCode: barCode,
            note: null,
            quantity: 1,
            unit: unit,
          }),
        )
      } else {
        foudItem.quantity++
      }

      this.$emit('input', order)

      this.$notify({
        group: 'scannedResult',
        title: 'ผลการสแกน ' + this.scannedData,
        type: 'info',
        text: 'เพิ่ม ' + name + ' ในบิลสำเร็จ',
      })
    },
    async searchProductByBarCode() {
      this.isLoading = true
      try {
        const searchData = this.scannedCache.find(data => data.barCode == this.scannedData)

        if (!searchData) {
          const response = await asyncGet(this.url + '/' + this.scannedData + '/barcode', {
            warehouse_id: this.warehouse_id,
          })

          this.scannedCache.push(response)

          this.addItem(response)
        } else {
          this.addItem(searchData)
        }
      } catch (error) {
        this.$notify({
          group: 'scannedResult',
          title: 'ผลการสแกน ' + this.scannedData,
          type: 'error',
          text: error,
        })
      }

      this.scannedData = ''
    },
    deleteItem(index) {
      const order = this.$attrs.value
      const newOrderItems = [...order.fromOrderItems]
      newOrderItems.splice(index, 1)
      order.fromOrderItems = newOrderItems

      this.$emit('input', order)
    },
    filterItem(item, queryText, itemText) {
      const tokens = queryText.split(' ')

      const searchText = tokens == queryText ? queryText : tokens[0]

      return (
        itemText.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1 ||
        (item.sku && item.sku.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1) ||
        (item.barCode && item.barCode.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) > -1)
      )
    },
    getDiscountRules(discount_value, discount_type) {
      return discount_type == 'percent'
        ? [minValueValidator(discount_value, 0), maxValueValidator(discount_value, 100)]
        : [minValueValidator(discount_value, 0)]
    },
  },
  components: {
    ToogleTextField,
    LightBox,
  },
}
</script>
<style scoped>
.product-input-mobile {
  display: flex;
  flex-direction: column;
}

.product-input-mobile > .title {
  background-color: gray;
  color: white;
}

.product-input-mobile > .product-item {
  display: flex;
  flex-direction: column;
}

.product-input-mobile > .product-item > .product-item-tr {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
}

.product-input-mobile > .product-item > .product-item-tr > .product-item-image {
  max-width: 100px;
  width: 100px;
  height: 100px;
}

.product-input-mobile > .product-item > .product-item-tr > .product-item-header {
  width: 100px;
  min-width: 100px;
}

.product-input-mobile > .product-item > .product-item-tr > .product-item-content {
  width: 100%;
}
</style>